import React from 'react';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import { DARKLY_TOGGLE_NAMES } from '../../../../main-deprecated/utils/launchDarklyToggles';
import CorporationList from '../../../../main-deprecated/views/corporations/corporationList/CorporationList';
import CreateCorporation from '../../../../main-deprecated/views/corporations/createCorporation/CreateCorporation';
import ForgotMyPassword from '../../../../main-deprecated/views/forgotMyPassword';
import Login from '../../../../main-deprecated/views/login';
import PasswordVerificationOrResetPage from '../../../../main-deprecated/views/PasswordVerificationOrReset/PasswordVerificationOrResetPage';
import PositionTemplateList from '../../../../main-deprecated/views/positionTemplate/positionTemplateList/PositionTemplateList';
import Register from '../../../../main-deprecated/views/register/Register';
import Settings from '../../../../main-deprecated/views/settings';
import SkillsAndCerts from '../../../../main-deprecated/views/skillsAndCerts';
import TimekeepingGridView from '../../../../main-deprecated/views/timekeeping/timekeepingGridView/TimekeepingGridView';
import UserList from '../../../../main-deprecated/views/users/userList/UserList';
import AgencyCreatePage from '../../dashboard/Agencies/AgencyCreatePage';
import Dashboard from '../../dashboard/Dashboard';
import JobOrderDetail from '../../jobOrders/jobOrderDetail';
import JobOrders from '../../jobOrders/JobOrders';
import JobOrdersCreate from '../../jobOrdersCreate/JobOrdersCreate';
import Reporting from '../../reporting/Reporting';
import UserDetail from '../../usersList/userDetail/UserDetail';
import UsersList from '../../usersList/UsersList';

import { ROUTE_CONSTANTS, searchParamOptions } from './routeConstants';

export const ROUTE_MAPPINGS = {
  [ROUTE_CONSTANTS.BASE]: {
    basePath: ROUTE_CONSTANTS.BASE,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER, UserRole.CANDIDATE],
    component: <Login />,
  },
  [ROUTE_CONSTANTS.REGISTER]: {
    basePath: ROUTE_CONSTANTS.REGISTER,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER, UserRole.CANDIDATE],
    component: <Register />,
  },
  [ROUTE_CONSTANTS.ACCOUNT_VERIFICATION_QUERY_INFORMATION]: {
    basePath: ROUTE_CONSTANTS.ACCOUNT_VERIFICATION_QUERY_INFORMATION,
    allowedUsers: [
      UserRole.ADMIN,
      UserRole.EMPLOYER,
      UserRole.RECRUITER,
      UserRole.CANDIDATE,
      undefined,
    ],
    requiredParams: ['queryinformation'],
    component: <PasswordVerificationOrResetPage type="accountVerification" />,
  },
  [ROUTE_CONSTANTS.PASSWORD_RESET_QUERY_INFORMATION]: {
    basePath: ROUTE_CONSTANTS.PASSWORD_RESET_QUERY_INFORMATION,
    allowedUsers: [
      UserRole.ADMIN,
      UserRole.EMPLOYER,
      UserRole.RECRUITER,
      UserRole.CANDIDATE,
      undefined,
    ],
    requiredParams: ['queryinformation'],
    component: <PasswordVerificationOrResetPage type="passwordReset" />,
  },
  [ROUTE_CONSTANTS.FORGOT_MY_PASSWORD]: {
    basePath: ROUTE_CONSTANTS.FORGOT_MY_PASSWORD,
    allowedUsers: [
      UserRole.ADMIN,
      UserRole.EMPLOYER,
      UserRole.RECRUITER,
      UserRole.CANDIDATE,
      undefined,
    ],
    component: <ForgotMyPassword />,
  },
  [ROUTE_CONSTANTS.DASHBOARD_AGENCY]: {
    basePath: ROUTE_CONSTANTS.DASHBOARD_AGENCY,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER],
    component: <Dashboard />,
    requiredParams: ['id'],
    featureFlag: DARKLY_TOGGLE_NAMES.isEnterpriseDashboardEnabled,
  },
  [ROUTE_CONSTANTS.DASHBOARD]: {
    basePath: ROUTE_CONSTANTS.DASHBOARD,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER],
    component: <Dashboard />,
    featureFlag: DARKLY_TOGGLE_NAMES.isEnterpriseDashboardEnabled,
  },
  [ROUTE_CONSTANTS.CORPORATIONS]: {
    basePath: ROUTE_CONSTANTS.CORPORATIONS,
    allowedUsers: [UserRole.ADMIN],
    component: <CorporationList />,
  },
  [ROUTE_CONSTANTS.CORPORATION_CREATE]: {
    basePath: ROUTE_CONSTANTS.CORPORATION_CREATE,
    allowedUsers: [UserRole.ADMIN],
    component: <CreateCorporation />,
  },
  [ROUTE_CONSTANTS.SKILLS_AND_CERTS]: {
    basePath: ROUTE_CONSTANTS.SKILLS_AND_CERTS,
    allowedUsers: [UserRole.ADMIN],
    component: <SkillsAndCerts />,
  },
  [ROUTE_CONSTANTS.REPORTING]: {
    basePath: ROUTE_CONSTANTS.REPORTING,
    allowedUsers: [UserRole.ADMIN],
    component: <Reporting />,
    featureFlag: DARKLY_TOGGLE_NAMES.isAdminMetricsPageEnabled,
  },
  [ROUTE_CONSTANTS.USERS]: {
    basePath: ROUTE_CONSTANTS.USERS,
    allowedUsers: [UserRole.ADMIN, UserRole.RECRUITER],
    component: <UserList />,
  },
  [ROUTE_CONSTANTS.SETTINGS]: {
    basePath: ROUTE_CONSTANTS.SETTINGS,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER],
    component: <Settings />,
  },
  [ROUTE_CONSTANTS.JOB_ORDERS]: {
    basePath: ROUTE_CONSTANTS.JOB_ORDERS,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER],
    component: <JobOrders />,
  },
  [ROUTE_CONSTANTS.JOB_ORDER_DETAIL]: {
    basePath: ROUTE_CONSTANTS.JOB_ORDER_DETAIL,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER],
    requiredParams: ['id'],
    component: <JobOrderDetail />,
  },
  [ROUTE_CONSTANTS.USERS_V2]: {
    basePath: ROUTE_CONSTANTS.USERS_V2,
    allowedUsers: [UserRole.ADMIN, UserRole.RECRUITER],
    component: <UsersList />,
  },
  [ROUTE_CONSTANTS.USERS_V2_DETAIL]: {
    basePath: ROUTE_CONSTANTS.USERS_V2_DETAIL,
    allowedUsers: [UserRole.ADMIN, UserRole.RECRUITER],
    requiredParams: ['id'],
    component: <UserDetail />,
  },
  [ROUTE_CONSTANTS.LONG_TERM_JOBS]: {
    basePath: ROUTE_CONSTANTS.LONG_TERM_JOBS,
    allowedUsers: [UserRole.ADMIN, UserRole.EMPLOYER, UserRole.RECRUITER],
    component: <JobOrders />,
  },
  [ROUTE_CONSTANTS.JOB_ORDER_CREATE]: {
    basePath: ROUTE_CONSTANTS.JOB_ORDER_CREATE,
    allowedUsers: [UserRole.ADMIN, UserRole.RECRUITER, UserRole.EMPLOYER],
    component: <JobOrdersCreate />,
  },
  [ROUTE_CONSTANTS.POSITION_TEMPLATES]: {
    basePath: ROUTE_CONSTANTS.POSITION_TEMPLATES,
    allowedUsers: [UserRole.ADMIN, UserRole.RECRUITER],
    component: <PositionTemplateList />,
  },
  [ROUTE_CONSTANTS.TIMEKEEPING]: {
    basePath: ROUTE_CONSTANTS.TIMEKEEPING,
    allowedUsers: [UserRole.ADMIN, UserRole.RECRUITER, UserRole.EMPLOYER],
    optionalParams: [
      searchParamOptions.WEEK_START,
      searchParamOptions.CANDIDATE,
      searchParamOptions.PLACEMENT,
      searchParamOptions.SELECTED_DATE,
    ],
    component: <TimekeepingGridView />,
    featureFlag: DARKLY_TOGGLE_NAMES.isTimesheetEnabled,
  },
  [ROUTE_CONSTANTS.AGENCY_CREATE]: {
    basePath: ROUTE_CONSTANTS.AGENCY_CREATE,
    allowedUsers: [UserRole.ADMIN],
    component: <AgencyCreatePage />,
  },
};

export default ROUTE_MAPPINGS;
