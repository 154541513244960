import * as React from 'react';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import adminUpdateUserRequest from '@careerstart/wae-common/schema/users/patch/admin.req.json';
import US_STATES from '@careerstart/wae-common/src/main/constants/US_STATES';
import {
  USER_EDITABLE_FIELDS,
  USER_STATUS,
} from '@careerstart/wae-common/src/main/constants/USER_EDITABLE_FIELDS';
import { convertReliabilityScoreToDisplayValue } from '@careerstart/wae-common/src/main/helperFunction/convertReliabilityScoreToDisplayValue';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import {
  formatEditablePhoneNumber,
  validateAddress,
  validateEmail,
  validatePhoneNumber,
} from '@careerstart/wae-common/src/main/helperFunction/profileHelperss';
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import stringAvatar from '../../../../main-deprecated/components/NavigationBar/SubComponents/stringAvatar';
import { selectSendVerificationEmailButtonDisabled } from '../../../../main-deprecated/store/selectors/accountVerificationSelector';
import CopyLinkIcon from '../../../assets/icons/CopyLinkIcon';
import theme from '../../../theme';
import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import ResendVerificationEmailModal from '../../../views/usersList/userDetail/components/workerComponents/ResendVerificationModal';
import { updateUser } from '../../../views/usersList/usersListReducer';
import { validateSchema } from '../../Form/validations';
import { CANDIDATE_STATUS_CONFIG } from '../constant';
import { convertPreferredTimesFromApiToUiFormat } from '../helpers';

import AgencyCard from './AgencyCard';
import Details from './Details';

const styles = theme?.components?.userCard;

const EditableCandidateCard = ({ user, onCopyId, onClick }) => {
  const isUserDeactivated = user?.status === USER_STATUS.DEACTIVATED;
  const dispatch = useDispatch();
  const updatedPreferredShiftTimes = user?.candidate?.preferredShiftTimes
    ? convertPreferredTimesFromApiToUiFormat(user?.candidate?.preferredShiftTimes)
    : [];
  const userInfo = {
    status: user?.status,
    role: user?.role,
    email: user?.email,
    name: user?.name,
    lastLogin: user?.lastLoginDate || null,
    reliabilityScore: convertReliabilityScoreToDisplayValue(
      user?.candidate?.metrics?.reliabilityScore
    ),
    attendance: user?.rating?.strikeOuts?.length > 0 ? 3 : user?.rating?.infractions?.length,
    preferredShiftTimes: updatedPreferredShiftTimes,
    industry: user?.candidate?.industry,
    phoneNumber: formatEditablePhoneNumber(user?.candidate?.phoneNumber),
    address: user?.candidate?.preferredLocation,
    agency: user?.candidate?.agency?.name || 'Test Agency',
  };

  const [isPhoneEditing, setIsPhoneEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isAddressEditing, setIsAddressEditing] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(
    userInfo?.phoneNumber ? formatEditablePhoneNumber(userInfo.phoneNumber) : ''
  );
  const [email, setEmail] = useState(userInfo?.email || '');
  const [address, setAddress] = useState({
    address1: userInfo?.address?.address1 || '',
    city: userInfo?.address?.city || '',
    state: userInfo?.address?.state || 'NY',
    zipcode: userInfo?.address?.zipcode || '',
  });
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false);
  const isVerificationEmailButtonDisabled = useSelector(selectSendVerificationEmailButtonDisabled);

  const currentStatus = CANDIDATE_STATUS_CONFIG[userInfo?.status];

  const handleUserUpdate = useCallback(
    (type, newValue) => {
      let fields = {};
      switch (type) {
        case USER_EDITABLE_FIELDS.PHONE_NUMBER:
          fields = {
            candidate: {
              phoneNumber: newValue,
            },
          };
          break;
        case USER_EDITABLE_FIELDS.EMAIL:
          fields = {
            email: newValue,
          };
          break;

        case USER_EDITABLE_FIELDS.ADDRESS:
          fields = {
            candidate: {
              preferredLocation: newValue,
            },
          };
          break;
        default:
          return;
      }

      const data = { id: user?.id, fields };
      if (Object.keys(validateSchema(data, adminUpdateUserRequest)).length === 0) {
        dispatch(updateUser(data));
      }
    },
    [dispatch, user?.id]
  );

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    if (!/^[()\d\s-]*$/.test(value)) return;
    const digits = value.replace(/\D/g, '');
    if (digits.length > 10) return;
    setPhoneNumber(formatEditablePhoneNumber(value));
    setIsPhoneEditing(true);
  };

  const handlePhoneSave = () => {
    const error = validatePhoneNumber(phoneNumber);
    setPhoneError(error);
    if (!error) {
      const rawPhoneNumber = phoneNumber.replace(/\D/g, '');
      handleUserUpdate(USER_EDITABLE_FIELDS.PHONE_NUMBER, rawPhoneNumber);
      setIsPhoneEditing(false);
    }
  };

  const handleEmailSave = () => {
    const error = validateEmail(email);
    setEmailError(error);
    if (!error) {
      handleUserUpdate(USER_EDITABLE_FIELDS.EMAIL, email);
      setIsEmailEditing(false);
    }
  };

  const handleAddressSave = () => {
    const error = validateAddress(address);
    setAddressError(error);
    if (!error) {
      handleUserUpdate(USER_EDITABLE_FIELDS.ADDRESS, address);
      setIsAddressEditing(false);
    }
  };

  const handleAddressChange = (field) => (event) => {
    const { value } = event.target;
    setAddress((prev) => ({
      ...prev,
      [field]: value,
    }));
    setIsAddressEditing(true);
  };

  const handleOpenEmailVerificationModal = () => {
    setIsResendEmailModalOpen(true);
  };

  return (
    <Card sx={{ ...styles.cardStyle, height: 'auto' }} onClick={onClick}>
      <CardHeader
        sx={styles.cardHeader}
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <Typography sx={{ fontFamily: PRIMARY_FONT[500] }}>
              {LanguageConverter('userCard.header.status')}
            </Typography>
            {currentStatus?.icon}
            <Typography sx={{ fontFamily: PRIMARY_FONT[500], color: currentStatus?.color }}>
              {LanguageConverter(currentStatus?.displayName)}
            </Typography>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar {...stringAvatar(user)} sx={{ width: 80, height: 80 }} />
          <Box>
            <Typography sx={styles.workerTitle}>
              {LanguageConverter('userCard.subTitle.worker')}
            </Typography>
            <Typography sx={styles.roleName}>{userInfo?.name}</Typography>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              onClick={onCopyId}
              sx={{ cursor: 'pointer', gap: 1 }}
            >
              <Typography sx={styles.copyUser}>
                {LanguageConverter('userCard.subTitle.copyUserId')}
              </Typography>
              <CopyLinkIcon />
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Box display="flex" flexDirection="column">
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.subTitle.phone')}
            </Typography>
            <Box sx={styles.phoneContainer}>
              <TextField
                disabled={isUserDeactivated}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder={LanguageConverter('userCard.phone.placeholder')}
                variant="outlined"
                inputProps={{
                  maxLength: 14,
                }}
                sx={styles.phoneInput}
              />
              <Button
                variant="outlined"
                onClick={handlePhoneSave}
                data-testid="phone-save-button"
                disabled={isUserDeactivated}
                sx={{
                  color: isPhoneEditing ? PRIMARY_PURPLE[70] : BLACK[60],
                  fontFamily: PRIMARY_FONT[500],
                  borderColor: isPhoneEditing ? PRIMARY_PURPLE[70] : BLACK[60],
                  height: '40px',
                }}
              >
                {LanguageConverter('buttonText.save')}
              </Button>
            </Box>
          </Box>
          {phoneError && (
            <Typography data-testid="phone-error-message" sx={styles.errorMessage}>
              {LanguageConverter(phoneError)}
            </Typography>
          )}
          <Box display="flex" flexDirection="column" marginTop={2}>
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.subTitle.email')}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <TextField
                disabled={isUserDeactivated}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsEmailEditing(true);
                }}
                sx={styles.editableEmailStyle}
              />
              <Button
                variant="outlined"
                onClick={handleEmailSave}
                data-testid="email-save-button"
                disabled={isUserDeactivated}
                sx={{
                  color: isEmailEditing ? PRIMARY_PURPLE[70] : BLACK[60],
                  fontFamily: PRIMARY_FONT[500],
                  borderColor: isEmailEditing ? PRIMARY_PURPLE[70] : BLACK[60],
                  height: '40px',
                }}
              >
                {LanguageConverter('buttonText.save')}
              </Button>
            </Box>
            {user?.status === USER_STATUS.ONBOARDING_INCOMPLETE && (
              <Button
                variant="contained"
                onClick={handleOpenEmailVerificationModal}
                disabled={isVerificationEmailButtonDisabled}
                data-testid="resend-email-button"
                fullWidth
                sx={styles.deactivateButtonStyle}
              >
                {LanguageConverter('buttonText.resendVerification')}
              </Button>
            )}
            {emailError && (
              <Typography data-testid="email-error-message" sx={styles.errorMessage}>
                {LanguageConverter(emailError)}
              </Typography>
            )}
          </Box>
          <ResendVerificationEmailModal
            isOpen={isResendEmailModalOpen}
            onClose={() => setIsResendEmailModalOpen(false)}
            userID={user?.id}
            isDisabled={isUserDeactivated}
            userEmail={email}
          />
          <Box sx={{ mt: 2 }}>
            <Typography sx={styles.subTitleStyle}>
              {LanguageConverter('userCard.address.header')}
            </Typography>
            <TextField
              disabled={isUserDeactivated}
              fullWidth
              size="small"
              value={address.address1}
              onChange={handleAddressChange(USER_EDITABLE_FIELDS.ADDRESS1)}
              placeholder={LanguageConverter('userCard.address.placeholder')}
              sx={{ mb: 1 }}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                disabled={isUserDeactivated}
                fullWidth
                size="small"
                value={address.city}
                onChange={handleAddressChange(USER_EDITABLE_FIELDS.CITY)}
                placeholder={LanguageConverter('userCard.city.placeholder')}
                sx={{ width: '75%' }}
              />
              <Select
                disabled={isUserDeactivated}
                data-testid="state-select"
                size="small"
                value={address.state}
                onChange={handleAddressChange(USER_EDITABLE_FIELDS.STATE)}
                sx={{ minWidth: 65, width: '25%' }}
              >
                {US_STATES.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <TextField
                disabled={isUserDeactivated}
                fullWidth
                size="small"
                value={address.zipcode}
                onChange={handleAddressChange(USER_EDITABLE_FIELDS.ZIP_CODE)}
                placeholder={LanguageConverter('userCard.zipcode.placeholder')}
                sx={{ width: '75%' }}
              />
              <Button
                variant="outlined"
                data-testid="address-save-button"
                onClick={handleAddressSave}
                disabled={!isAddressEditing}
                sx={{
                  color: isAddressEditing ? PRIMARY_PURPLE[70] : BLACK[60],
                  fontFamily: PRIMARY_FONT[500],
                  borderColor: isAddressEditing ? PRIMARY_PURPLE[70] : BLACK[60],
                  height: '40px',
                  width: '25%',
                }}
              >
                {LanguageConverter('buttonText.save')}
              </Button>
            </Box>
            {addressError && (
              <Typography data-testid="address-error-message" sx={styles.errorMessage}>
                {LanguageConverter(addressError)}
              </Typography>
            )}
          </Box>
        </Box>
        <AgencyCard agency={userInfo?.agency} candidateName={userInfo?.name} />
        <Details userInfo={userInfo} isEditable />
      </CardContent>
    </Card>
  );
};
EditableCandidateCard.propTypes = {
  user: PropTypes.shape({
    candidate: PropTypes.shape({
      agency: PropTypes.shape({ name: PropTypes.string }),
      preferredShiftTimes: PropTypes.shape({}),
      preferredLocation: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
      industry: PropTypes.arrayOf(PropTypes.string),
      phoneNumber: PropTypes.string,
      metrics: PropTypes.shape({
        reliabilityScore: PropTypes.number,
      }),
    }),
    role: PropTypes.string,
    status: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    lastLoginDate: PropTypes.string,
    reliabilityScore: PropTypes.number,
    attendance: PropTypes.number,
    phoneNumber: PropTypes.string,
    location: PropTypes.string,
    rating: PropTypes.shape({
      strikeOuts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
      infractions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    }),
  }),
  onCopyId: PropTypes.func,
  onClick: PropTypes.func,
};

export default EditableCandidateCard;
