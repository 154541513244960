import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CircularProgressArrowIcon = ({ color = '#1DA83B', number = 43, size = 105, sx = {} }) => {
  const calculateFontSize = () => {
    const numStr = number.toLocaleString();
    const { length } = numStr;
    if (length <= 2) return '2.5rem';
    if (length <= 3) return '2rem';
    if (length <= 4) return '1.8rem';
    if (length <= 6) return '1.5rem';
    return '1.2rem';
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
        ...sx,
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 105 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect x="1.5" y="1.5" width="90" height="90" rx="45" stroke={color} strokeWidth="3" />
        <rect
          x="78.4062"
          y="23.168"
          width="10"
          height="9"
          transform="rotate(-30.5738 78.4062 23.168)"
          fill="white"
        />
        <path
          d="M94.8588 31.4084L86.7815 26.6577L81.7294 34.5501"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Typography
        variant="body1"
        component="div"
        sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: calculateFontSize(),
          fontWeight: 'bold',
          width: '70%',
          height: '70%',
          textAlign: 'center',
          userSelect: 'none',
          lineHeight: 1,
          left: '45%',
          top: '47%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          margin: 0,
        }}
      >
        {number.toLocaleString()}
      </Typography>
    </Box>
  );
};

CircularProgressArrowIcon.propTypes = {
  color: PropTypes.string,
  number: PropTypes.number,
  size: PropTypes.number,
  sx: PropTypes.shape({}),
};

export default CircularProgressArrowIcon;
