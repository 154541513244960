import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';

import CircularProgressArrowIcon from '../../assets/icons/CircularProgressArrowIcon';
import { PRIMARY_PURPLE } from '../../theme/colorConstants';
import theme from '../../theme/theme';

const styles = theme?.components?.remainingActionsCounterCard;

const RemainingActionsCounterCard = ({ data }) => {
  const [cardSelected, setCardSelected] = React.useState(false);

  const handleCardClick = () => {
    setCardSelected(!cardSelected);
  };

  return (
    <Card
      variant={cardSelected ? 'outlined' : 'elevation'}
      onClick={handleCardClick}
      sx={{
        width: '60%',
        minWidth: '380px',
        minHeight: '250px',
        backgroundColor: cardSelected ? PRIMARY_PURPLE[10] : 'white',
        boxShadow: cardSelected
          ? `0px 2px 6px 0px rgba(0, 0, 0, 0.25), 0 0 0 2px ${PRIMARY_PURPLE[70]}`
          : '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
        border: cardSelected ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
        '&:hover': {
          backgroundColor: PRIMARY_PURPLE[10],
        },
      }}
    >
      <CardContent sx={{ textAlign: 'left' }}>
        <Typography sx={styles.headerStyle} data-testid="header">
          {LanguageConverter(data?.header)}
        </Typography>
        <Typography sx={styles.subHeaderStyle} data-testid="subHeader">
          {LanguageConverter(data?.subHeader)}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgressArrowIcon color={data?.color} number={data?.count} />
            <Box sx={styles.textContainer} />
          </Box>
          <Box>
            <Typography sx={styles.bodyStyle} data-testid="body">
              {LanguageConverter(data?.body)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" sx={styles.buttonStyle} data-testid="button">
            {LanguageConverter(data?.buttonText)}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

RemainingActionsCounterCard.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    subHeader: PropTypes.string,
    body: PropTypes.string,
    count: PropTypes.number,
    color: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

export default RemainingActionsCounterCard;
