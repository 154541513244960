import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';

import AgencySelectionModal from './AgencySelectionModal';

const styles = theme?.components?.userCard;
const AgencyCard = ({ agency, candidateName }) => {
  const [isAgencySelectionModalOpen, setIsAgencySelectionModalOpen] = useState(false);

  return (
    <>
      <Box sx={{ mt: 2, mb: 1 }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography sx={styles.subTitleStyle}>{LanguageConverter('userCard.agency')} </Typography>
          <Typography sx={styles.agencyStyle}>{agency}</Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={styles.deactivateButtonStyle}
          onClick={() => setIsAgencySelectionModalOpen(true)}
        >
          {LanguageConverter('userCard.changeAgency')}
        </Button>
      </Box>
      <AgencySelectionModal
        candidateName={candidateName}
        open={isAgencySelectionModalOpen}
        onClose={() => setIsAgencySelectionModalOpen(false)}
      />
    </>
  );
};

AgencyCard.propTypes = {
  agency: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
};

export default AgencyCard;
