import React from 'react';
import { Route } from 'react-router-dom';

import launchDarklyToggles from '../../../../main-deprecated/utils/launchDarklyToggles';

import { ROUTE_MAPPINGS } from './routeMappings';

export const generatePath = (basePath, requiredParams = [], optionalParams = []) => {
  let pathWithSlash = basePath.startsWith('/') ? basePath : `/${basePath}`;

  // Ensure required params are included only if they are not in the base path
  requiredParams.forEach((param) => {
    if (!pathWithSlash.includes(`:${param}`)) {
      pathWithSlash += `/:${param}`;
    }
  });

  // Append optional params only if they are not in the base path
  optionalParams.forEach((param) => {
    if (!pathWithSlash.includes(`:${param}`)) {
      pathWithSlash += `/:${param}?`;
    }
  });
  return pathWithSlash;
};

export const generateRoutesByRole = (userRole) =>
  Object.keys(ROUTE_MAPPINGS)
    .map((key) => {
      const route = ROUTE_MAPPINGS[key];
      return {
        allowedUsers: route.allowedUsers,
        path: generatePath(route.basePath, route.requiredParams, route.optionalParams),
        component: route.component,
        featureFlag: route.featureFlag || null, // Feature flag is optional, may not exist
      };
    })
    .filter((route) => route.allowedUsers.includes(userRole));

export const filterRoutesByFlag = (routes, flags = {}) =>
  routes.filter((route) => {
    if (route.featureFlag) {
      // If there's a feature flag, check if it's enabled in the flags object
      return launchDarklyToggles(flags, route.featureFlag) === true || flags[route.featureFlag];
    }
    // If there's no feature flag, include the route by default
    return true;
  });

export const generateRoutesJSX = (userRole, flags) => {
  const routesAvailableToRole = generateRoutesByRole(userRole);
  const routesFilteredByFlag = filterRoutesByFlag(routesAvailableToRole || [], flags);

  return routesFilteredByFlag.map((route) => {
    const { path, component } = route;

    return <Route key={path} path={path} element={component} />;
  });
};
