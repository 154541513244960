import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import theme from '../../../theme';
import { PRIMARY_PURPLE } from '../../../theme/colorConstants';

const styles = theme?.components?.userCard;

const agencies = [
  { id: 1, name: 'Agency Emma' },
  { id: 2, name: 'Agency Test' },
  { id: 3, name: 'Agency Placeholder' },
];

const AgencySelectionModal = ({ open, onClose, candidateName }) => {
  const [selectedAgency, setSelectedAgency] = useState(null);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography sx={styles.agencyHeaderStyle}>
          {LanguageConverterV2('userCard.agency.header', { candidate: candidateName })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          value={selectedAgency}
          onChange={(event, newValue) => {
            setSelectedAgency(newValue);
          }}
          options={agencies}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="normal"
              placeholder={LanguageConverter('userCard.agency.placeholder')}
              InputProps={{
                ...params.InputProps,
                sx: styles.agencyTextfieldStyle,
              }}
            />
          )}
          sx={{
            '& .MuiAutocomplete-tag': {
              backgroundColor: PRIMARY_PURPLE[70],
              color: 'white',
            },
          }}
        />
        <Box sx={styles.agencyButtonContainer}>
          <Button variant="outlined" onClick={onClose} sx={styles.backButton}>
            {LanguageConverter('buttonText.goBack')}
          </Button>
          <Button variant="contained" sx={styles.agencyChangeButton}>
            {LanguageConverter('buttonText.changeAgency')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AgencySelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
};

export default AgencySelectionModal;
