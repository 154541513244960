import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  widgetData: undefined,
  jobOrders: null,
  agencies: [],
  agencyTotalCount: 0,
  isWidgetDataLoading: false,
  isJobOrdersLoading: false,
  isAgenciesLoading: false,
  widgetError: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getWidgetData: (state) => {
      state.isWidgetDataLoading = true;
      state.widgetError = null;
    },
    getWidgetDataProcessed: (state, action) => {
      state.isWidgetDataLoading = false;
      state.widgetData = action.payload.data ?? null;
    },
    getWidgetDataError: (state, action) => {
      state.isWidgetDataLoading = false;
      state.widgetError = action.payload;
    },
    getJobOrdersForDashboard: (state) => {
      state.isJobOrdersLoading = true;
    },
    getJobOrdersForDashboardProcessed: (state, action) => {
      state.isJobOrdersLoading = false;
      state.jobOrders = action.payload.data;
    },
    getJobOrdersForDashboardError: (state) => {
      state.isJobOrdersLoading = false;
      state.jobOrders = initialState.jobOrders;
    },
    getAgenciesForDashboard: (state, action) => {
      if (action?.payload?.page === 0) {
        state.agencies = initialState.agencies;
      }
      state.isAgenciesLoading = true;
    },
    getAgenciesForDashboardProcessed: (state, action) => {
      state.isAgenciesLoading = false;
      state.agencies = [...state.agencies, ...action.payload.data.documents];
      state.agencyTotalCount = action.payload.data.count;
    },
    getAgenciesForDashboardError: (state) => {
      state.isAgenciesLoading = false;
    },
    createAgency: (state) => {
      state.isCreatingAgency = true;
    },
    createAgencyProcessed: (state) => {
      state.isCreatingAgency = false;
    },
    createAgencyError: (state) => {
      state.isCreatingAgency = false;
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const {
  getWidgetData,
  getWidgetDataError,
  getWidgetDataProcessed,
  getJobOrdersForDashboard,
  getJobOrdersForDashboardProcessed,
  getJobOrdersForDashboardError,
  getAgenciesForDashboard,
  getAgenciesForDashboardProcessed,
  getAgenciesForDashboardError,
  createAgency,
  createAgencyProcessed,
  createAgencyError,
} = dashboardSlice.actions;
