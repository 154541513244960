import { createSelector } from 'reselect';

export const selectDashboardState = (state) => state?.dashboardState;

export const selectIsWidgetDataLoading = createSelector(
  selectDashboardState,
  (state) => state.isWidgetDataLoading
);

export const selectWidgetData = createSelector(selectDashboardState, (state) => state.widgetData);

export const selectIsJobOrdersLoading = createSelector(
  selectDashboardState,
  (state) => state.isJobOrdersLoading
);

export const selectJobOrders = createSelector(selectDashboardState, (state) => state.jobOrders);
export const selectWidgetError = createSelector(selectDashboardState, (state) => state.widgetError);

export const selectIsAgenciesLoading = createSelector(
  selectDashboardState,
  (state) => state.isAgenciesLoading
);

export const selectAgencies = createSelector(selectDashboardState, (state) => state.agencies);
export const selectAgencyTotalCount = createSelector(
  selectDashboardState,
  (state) => state.agencyTotalCount
);
