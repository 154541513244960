export const dateToYearMonthDayMinutes = (date, minutes) => {
  const newObject = {
    year: date.getYear() + 1900,
    day: date.getDate(),
    month: date.getMonth() + 1,
    minutes,
  };
  return newObject;
};

// capitalize the first letter of a string
export const setFirstLetterOfStringToCapital = (word) =>
  word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word;

export const getUserTimeZone = () => {
  const d = new Date();
  const tz = d.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
  return tz;
};

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isShiftCompleted = (endTime) => endTime + 900000 < Date.now(); // 15 min after the end time
export const isShiftStarted = (shiftStart) => shiftStart - 900000 < Date.now(); // 15 min before the start time

// Convert a number to a dollar currency format (e.g., 1234 -> $1,234.00)
export const convertToDollars = (number) => `$${number.toLocaleString('en-US')}`;
