import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AdminDashboardReadSchemaRequest from '@careerstart/wae-common/schema/agency/dashboard/admin.agency.dashboard.read.req.json';
import AgencyDashboardReadSchemaRequest from '@careerstart/wae-common/schema/agency/dashboard/recruiter.agency.dashboard.read.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import { validateSchema } from '../../components/Form/validations';
import ScoreChart from '../../components/ScoreChart';
import selectUser from '../../store/selectors/appSelector';
import {
  selectIsWidgetDataLoading,
  selectWidgetData,
  selectWidgetError,
} from '../../store/selectors/dashboardSelector';
import { PRIMARY_FONT } from '../../theme/fontConstants';

import Agencies from './Agencies/Agencies';
import DashboardJobs from './DashboardJobs';
import DashboardOnboarding from './DashboardOnboarding';
import { getWidgetData } from './dashboardReducer';
import OpeningsAndPlacementsWidget from './OpeningsAndPlacementsWidget';
import PotentialRevenueWidget from './PotentialRevenueWidget';
import WorkerStatusWidget from './WorkerStatusWidget';

const Dashboard = () => {
  const dispatch = useDispatch();
  const widgetData = useSelector(selectWidgetData);
  const widgetError = useSelector(selectWidgetError);
  const { workers, revenue, futurePlacementsData } = widgetData || {};

  const isWidgetDataLoading = useSelector(selectIsWidgetDataLoading);
  const user = useSelector(selectUser);

  const { id } = useParams();
  const pathSelectedAgencyId = id;

  const isAdminOrRecruiter = user?.role === UserRole.ADMIN || user?.role === UserRole.RECRUITER;

  const adminRevenueLabels = {
    title: LanguageConverter('dashboard.widget.admin.revenue.title'),
    totalRevenue: LanguageConverter('dashboard.widget.admin.revenue.total'),
    scheduled: LanguageConverter('dashboard.widget.admin.revenue.booked'),
  };

  const agencyRevenueLabels = {
    title: LanguageConverter('dashboard.widget.agency.revenue.title'),
    availableRevenue: LanguageConverter('dashboard.widget.agency.revenue.available'),
    totalRevenue: LanguageConverter('dashboard.widget.agency.revenue.total'),
    totalRevenueDescription: LanguageConverter('dashboard.widget.agency.revenue.total.description'),
    scheduled: LanguageConverter('dashboard.widget.agency.revenue.scheduled'),
  };

  const revenueLabels = user?.role === UserRole.ADMIN ? adminRevenueLabels : agencyRevenueLabels;

  const isRecruiter = user?.role === UserRole.RECRUITER;
  const isAgencyDashboard = isRecruiter || pathSelectedAgencyId;

  useEffect(() => {
    if (!isWidgetDataLoading && widgetData === undefined && !widgetError) {
      const payload = {};
      if (isAgencyDashboard) {
        payload.fields = {
          agency_id: pathSelectedAgencyId || user?.id, // FIXME:HB-4166 use user.recruiter.agency when mig
        };
      }

      const schemaErrors = validateSchema(
        payload,
        isRecruiter ? AgencyDashboardReadSchemaRequest : AdminDashboardReadSchemaRequest
      );
      if (Object.keys(schemaErrors).length === 0) {
        dispatch(getWidgetData(payload));
      }
    }
  }, [
    dispatch,
    isAgencyDashboard,
    isRecruiter,
    isWidgetDataLoading,
    pathSelectedAgencyId,
    user,
    widgetData,
    widgetError,
  ]);

  return isAdminOrRecruiter ? (
    <Box padding="32px" display="flex" flexDirection="column" gap="32px">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography sx={{ fontFamily: PRIMARY_FONT[700], fontSize: 36 }}>
          {isAgencyDashboard
            ? LanguageConverter('dashboard.agency.title')
            : LanguageConverter('dashboard.admin.title')}
        </Typography>
        {user?.role === UserRole.ADMIN && <DashboardOnboarding />}
        {isAgencyDashboard && !isWidgetDataLoading && workers && (
          <ScoreChart
            label={LanguageConverter('dashboard.widget.reliability.title')}
            score={workers.averageReliabilityScore}
            description={LanguageConverter('dashboard.widget.reliability.description')}
          />
        )}
        {user?.role === UserRole.RECRUITER && <DashboardOnboarding />}
      </Box>

      {!isWidgetDataLoading && widgetData && (
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} xl={4}>
            <WorkerStatusWidget
              isLoading={isWidgetDataLoading}
              activeWorkers={workers.active}
              pendingOnboarding={workers.pendingOnboarding}
              waitingVerification={workers.waitingVerification}
              atRisk={workers.atRisk}
              total={workers.total}
            />
          </Grid>

          <Grid item xs={12} lg={6} xl={4}>
            <PotentialRevenueWidget
              labels={revenueLabels}
              availableAmount={Math.round(revenue.unscheduled)}
              totalEarnedAmount={Math.round(revenue.earnedTrailing12Month)}
              scheduledAmount={Math.round(revenue.scheduled)}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <OpeningsAndPlacementsWidget
              isLoading={isWidgetDataLoading}
              totalOpenings={futurePlacementsData.totalOpenings}
              totalActivePlacements={futurePlacementsData.totalActivePlacements}
              percentStaffed={Math.round(
                (futurePlacementsData.totalActivePlacements * 100) /
                  futurePlacementsData.totalOpenings
              )}
              totalWorkerCancellations={futurePlacementsData.totalWorkerCancellations}
              cancellationToPlacementRatio={futurePlacementsData.cancellationToPlacementRatio}
            />
          </Grid>
        </Grid>
      )}

      {isWidgetDataLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isWidgetDataLoading && !widgetData && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography> {LanguageConverter('dashboard.noDataAvailable')} </Typography>
        </Box>
      )}

      {isAgencyDashboard && <DashboardJobs />}
      {user?.role === UserRole.ADMIN && <Agencies />}
    </Box>
  ) : null;
};

export default Dashboard;
