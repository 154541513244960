import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import { showSnackbar } from '../../views/app';

import AdminCard from './components/AdminCard';
import CandidateCard from './components/CandidateCard';
import EditableCandidateCard from './components/EditableCandidateCard';
import EmployerCard from './components/EmployerCard';
import RecruiterCard from './components/RecruiterCard';

const UserCard = ({ user, onClick, isEditable }) => {
  const dispatch = useDispatch();

  const copiedMessage = LanguageConverter('userCard.message.idCopied');

  const ROLE_CARD_MAP = {
    [UserRole.CANDIDATE]: isEditable ? EditableCandidateCard : CandidateCard,
    [UserRole.RECRUITER]: RecruiterCard,
    [UserRole.ADMIN]: AdminCard,
    [UserRole.EMPLOYER]: EmployerCard,
  };

  const handleCopyUserId = () => {
    if (!user?.id) return;
    navigator.clipboard.writeText(user.id);
    dispatch(showSnackbar({ message: copiedMessage }));
  };
  const CardComponent = ROLE_CARD_MAP[user?.role];

  return <CardComponent user={user} onCopyId={handleCopyUserId} onClick={onClick} />;
};

UserCard.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  isEditable: PropTypes.bool,
};

export default UserCard;
