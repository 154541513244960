import { ACTION_BLUE, FEED_BACK } from '../../theme/colorConstants';

const getStepData = (counts) => [
  {
    color: FEED_BACK.GREEN_70,
    onSubmit: () => counts.step1.onSubmit(),
    header: 'timekeeping.counterCards.step1',
    subHeader: 'timekeeping.counterCards.step1.subHeader',
    body: 'timekeeping.counterCards.step1.body',
    buttonText: 'timekeeping.counterCards.selectButtonText',
    count: counts.step1.count,
  },
  {
    color: ACTION_BLUE.INDIGO_BLUE_REST,
    onSubmit: () => counts.step2.onSubmit(),
    header: 'timekeeping.counterCards.step2',
    subHeader: 'timekeeping.counterCards.step2.subHeader',
    body: 'timekeeping.counterCards.step2.body',
    buttonText: 'timekeeping.counterCards.viewButtonText',
    count: counts.step2.count,
  },
  {
    color: FEED_BACK.ORANGE_20,
    onSubmit: () => counts.step3.onSubmit(),
    header: 'timekeeping.counterCards.step3',
    subHeader: 'timekeeping.counterCards.step3.subHeader',
    body: 'timekeeping.counterCards.step3.body',
    buttonText: 'timekeeping.counterCards.viewButtonText',
    count: counts.step3.count,
  },
  {
    color: FEED_BACK.ORANGE_20,
    onSubmit: () => counts.step4.onSubmit(),
    header: 'timekeeping.counterCards.step4',
    subHeader: 'timekeeping.counterCards.step4.subHeader',
    body: 'timekeeping.counterCards.step4.body',
    buttonText: 'timekeeping.counterCards.viewButtonText',
    count: counts.step4.count,
  },
];

export default getStepData;
