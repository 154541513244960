import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverterV2 from '@careerstart/wae-common/src/main/helperFunction/LanguageConverterV2';
import { Box, CircularProgress, Typography } from '@mui/material';

import StatusIcon from '../../assets/icons/StatusIconInFilters.svg';
import FreeTextSearchFilter, { SEARCHBAR_BACKGROUND } from '../../components/FreeTextSearchFilter';
import NoResultsCard from '../../components/NoResultsCard/NoResultsCard';
import { SelectDropdownFilter } from '../../components/SelectDropdownFilter/SelectDropdownFilter';
import UserCard from '../../components/UserCard/UserCard';
import selectUser from '../../store/selectors/appSelector';
import { selectUserListData } from '../../store/selectors/userListSelector';
import { ROUTE_CONSTANTS } from '../app/RouteBuilding/routeConstants';

import AddUserMenuButton from './AddUserMenuButton';
import {
  INITIAL_FILTER_SORT_PAGE,
  ROLE_OPTIONS,
  SEARCH_PARAM_KEYS,
  SORT_VALUES,
  STATUS_OPTIONS,
} from './UsersConstants';
import {
  buildFilterPayloadFromUrl,
  buildSortPayloadFromUrl,
  getMainHeaderKey,
  getSubHeaderKey,
} from './UsersHelpers';
import { getUsers } from './usersListReducer';

const loginSortOptions = [
  { value: SORT_VALUES.ASC, name: 'Oldest' },
  { value: SORT_VALUES.DESC, name: 'Newest' },
];

const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const sentinelRef = useRef(null);
  const usersListData = useSelector(selectUserListData);
  const { isUsersLoading, totalRowCount, users } = usersListData;

  const [isResettingFilters, setIsResettingFilters] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filterAndSortData, setFilterAndSortData] = useState(() => {
    const parsedUrlFilters = buildFilterPayloadFromUrl(searchParams, []);
    const parsedUrlSorts = buildSortPayloadFromUrl(searchParams, []);

    return {
      ...INITIAL_FILTER_SORT_PAGE,
      filters: [...INITIAL_FILTER_SORT_PAGE.filters, ...parsedUrlFilters],
      sortBy: [...parsedUrlSorts],
    };
  });

  const previousFiltersRef = useRef();

  const initialRoleValue =
    ROLE_OPTIONS.find((fillRate) => searchParams.get(SEARCH_PARAM_KEYS.ROLE) === fillRate.value) ||
    null;

  const initialStatus =
    STATUS_OPTIONS.find((status) => searchParams.get(SEARCH_PARAM_KEYS.STATUS) === status.value) ||
    null;

  const initialLoginSort =
    loginSortOptions.find(
      (loginSort) => searchParams.get(SEARCH_PARAM_KEYS.LOGIN_SORT) === loginSort.value
    ) || null;

  const isUserAdmin = user?.role === UserRole.ADMIN;
  // const isUserRecruiter = user?.role === UserRole.RECRUITER;
  // const isUserAdminOrRecruiter = user?.role === isUserAdmin || isUserRecruiter;
  // console.log(isUserAdminOrRecruiter);

  useEffect(() => {
    if (isUsersLoading) return;
    const payload = {
      filters: filterAndSortData.filters,
      limit: filterAndSortData.pageSize,
      page: filterAndSortData.page,
      sortBy: filterAndSortData.sortBy,
    };

    if (!isEqual(previousFiltersRef.current, payload)) {
      previousFiltersRef.current = payload;

      dispatch(getUsers(payload));
    }
  }, [dispatch, filterAndSortData, isUsersLoading, searchParams]);

  useEffect(() => {
    setFilterAndSortData((prev) => {
      const filters = buildFilterPayloadFromUrl(searchParams, prev.filters);
      const sorts = buildSortPayloadFromUrl(searchParams, prev.sortBy);

      if (isEqual(prev.filters, filters) && isEqual(prev.sortBy, sorts)) {
        return prev;
      }

      if (filters.length > 0 || sorts.length > 0) {
        return { ...prev, page: 0, filters, sortBy: sorts };
      }
      return { ...INITIAL_FILTER_SORT_PAGE, page: 0 };
    });
  }, [searchParams]);

  useEffect(() => {
    if (isResettingFilters) {
      setIsResettingFilters(false);
      setFilterAndSortData(INITIAL_FILTER_SORT_PAGE);
      setSearchParams({});
    }
  }, [isResettingFilters, setSearchParams]);

  const handleIntersection = useCallback(
    (entries) => {
      if (entries?.[0].isIntersecting && totalRowCount > users?.length && !isUsersLoading) {
        setFilterAndSortData((prevVal) => ({
          ...prevVal,
          page: prevVal.page + 1,
        }));
      }
    },
    [users, totalRowCount, isUsersLoading]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [handleIntersection]);

  const handleRoleChange = useCallback(
    (fillRate) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!fillRate) {
        delete newParams[SEARCH_PARAM_KEYS.ROLE];
      } else {
        newParams[SEARCH_PARAM_KEYS.ROLE] = fillRate;
      }

      if (newParams[SEARCH_PARAM_KEYS.ROLE] !== searchParams.get(SEARCH_PARAM_KEYS.ROLE)) {
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleStatusChange = useCallback(
    (newStatus) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!newStatus) {
        delete newParams[SEARCH_PARAM_KEYS.STATUS];
      } else {
        newParams[SEARCH_PARAM_KEYS.STATUS] = newStatus;
      }

      if (newParams[SEARCH_PARAM_KEYS.STATUS] !== searchParams.get(SEARCH_PARAM_KEYS.STATUS)) {
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleLoginSortChange = useCallback(
    (newStatus) => {
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!newStatus) {
        delete newParams[SEARCH_PARAM_KEYS.LOGIN_SORT];
      } else {
        newParams[SEARCH_PARAM_KEYS.LOGIN_SORT] = newStatus;
      }

      if (
        newParams[SEARCH_PARAM_KEYS.LOGIN_SORT] !== searchParams.get(SEARCH_PARAM_KEYS.LOGIN_SORT)
      ) {
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const handleNameSearchChange = useCallback(
    (newNameFilter) => {
      const nameSearchTerm = newNameFilter?.[0]?.value;
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!nameSearchTerm) {
        delete newParams[SEARCH_PARAM_KEYS.NAME];
      } else {
        newParams[SEARCH_PARAM_KEYS.NAME] = nameSearchTerm;
      }
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const headerMsg = LanguageConverterV2(getMainHeaderKey(searchParams, user.role));
  const subHeaderMsg = LanguageConverterV2(getSubHeaderKey(searchParams));

  const handleUserCardClick = (userId) => {
    const userIdPath = generatePath(`/${ROUTE_CONSTANTS.USERS_V2}/:id`, {
      id: userId,
    });
    navigate(`${userIdPath}${location.search}`);
  };

  const MainListContent = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Box>
        <Typography sx={{ fontFamily: 'Barlow', fontSize: '32px', fontWeight: 800 }}>
          {headerMsg}
        </Typography>
        <Typography sx={{ fontFamily: 'Barlow', fontSize: '20px', fontWeight: 400 }}>
          {subHeaderMsg} ({totalRowCount})
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '25px',
        }}
      >
        {users?.map((u) => (
          <UserCard
            user={u}
            onClick={() => handleUserCardClick(u.id)}
            key={u?.id}
            isUserAdmin={isUserAdmin}
            isEditable={false}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px', gap: '24px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <AddUserMenuButton />
        {!isResettingFilters && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {LanguageConverterV2('buttonText.filters')}
            </Typography>

            {isUserAdmin && (
              <SelectDropdownFilter
                disabled={isUsersLoading}
                placeholder="Role"
                onValueChange={handleRoleChange}
                options={ROLE_OPTIONS}
                getOptionLabel={(option) => option.name}
                wrapperSx={{ width: '180px', padding: '0px 6px' }}
                initialValue={initialRoleValue}
              />
            )}

            <SelectDropdownFilter
              disabled={isUsersLoading}
              placeholder="Status"
              onValueChange={handleStatusChange}
              options={STATUS_OPTIONS}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  alt="Status"
                  src={StatusIcon}
                />
              }
              initialValue={initialStatus}
            />

            <FreeTextSearchFilter
              disabled={isUsersLoading}
              placeholder="Name"
              onValueChange={handleNameSearchChange}
              field="name"
              operation="icontains"
              background={SEARCHBAR_BACKGROUND.DEFAULT}
              initialValue={{ value: searchParams.get(SEARCH_PARAM_KEYS.NAME) }}
            />

            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {LanguageConverterV2('user.lastLoggedIn')}
            </Typography>

            <SelectDropdownFilter
              disabled={isUsersLoading}
              placeholder={LanguageConverterV2('buttonText.unsorted')}
              onValueChange={handleLoginSortChange}
              options={loginSortOptions}
              getOptionLabel={(option) => option.name}
              initialValue={initialLoginSort}
            />
          </Box>
        )}
      </Box>
      {users?.length === 0 && !isUsersLoading ? (
        <NoResultsCard
          headerMsgKey={null}
          bodyMsgKey="jobOrder.search.noResult"
          onResetFilter={() => {
            setIsResettingFilters(true);
          }}
        />
      ) : (
        <>
          <MainListContent />
          {isUsersLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      <div ref={sentinelRef} />
    </Box>
  );
};

export default UsersList;
