import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import agenciesReadReq from '@careerstart/wae-common/schema/agency/read.post.req.json';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';

import AgencyCard from '../../../components/AgencyCard/AgencyCard';
import { validateSchema } from '../../../components/Form/validations';
import {
  selectAgencies,
  selectAgencyTotalCount,
  selectIsAgenciesLoading,
} from '../../../store/selectors/dashboardSelector';
import { getAgenciesForDashboard } from '../dashboardReducer';

import AgenciesView from './AgenciesView';
import AgencyFilters from './AgencyFilters';
import { AGENCY_SEARCH_PARAM_KEYS } from './dashboardAgencyConstatnts';

const LIMIT = 5;

const INITIAL_FILTERS = {
  filters: [],
  limit: LIMIT,
  page: 0,
};

const Agencies = () => {
  const previousFiltersRef = useRef();
  const sentinelRef = useRef(null);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsAgenciesLoading);
  const agencies = useSelector(selectAgencies);
  const agencyTotalCount = useSelector(selectAgencyTotalCount);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterAndSortData, setFilterAndSortData] = useState(INITIAL_FILTERS);

  const handleOnNameSearchChange = useCallback(
    (newPositionFilter) => {
      const agencySearchTerm = newPositionFilter?.[0]?.value;
      const newParams = { ...Object.fromEntries(searchParams) };
      if (!agencySearchTerm) {
        delete newParams[AGENCY_SEARCH_PARAM_KEYS.AGENCY];
      } else {
        newParams[AGENCY_SEARCH_PARAM_KEYS.AGENCY] = agencySearchTerm;
      }
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );
  useEffect(() => {
    if (isLoading) return;
    if (!isEqual(previousFiltersRef.current, filterAndSortData)) {
      previousFiltersRef.current = filterAndSortData;
      if (Object.keys(validateSchema(filterAndSortData, agenciesReadReq)).length === 0) {
        dispatch(getAgenciesForDashboard(filterAndSortData));
      }
    }
  }, [dispatch, filterAndSortData, isLoading]);

  useEffect(() => {
    setFilterAndSortData((prev) => {
      const newFilter = searchParams.get(AGENCY_SEARCH_PARAM_KEYS.AGENCY)
        ? [
            {
              field: 'name',
              operation: 'icontains',
              value: searchParams.get(AGENCY_SEARCH_PARAM_KEYS.AGENCY),
            },
          ]
        : [];

      const filters = {
        filters: newFilter,
        limit: LIMIT,
        page: 0,
      };

      if (isEqual(prev?.filters, filters)) {
        return prev;
      }
      return filters;
    });
  }, [searchParams]);

  const handleIntersection = useCallback(
    (entries) => {
      if (entries?.[0].isIntersecting && agencyTotalCount > agencies?.length && !isLoading) {
        setFilterAndSortData((prevVal) => ({
          ...prevVal,
          page: prevVal.page + 1,
        }));
      }
    },
    [agencies, agencyTotalCount, isLoading]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [handleIntersection]);

  return (
    <Box>
      <AgenciesView
        isLoading={isLoading}
        content={agencies?.map((agency) => (
          <AgencyCard
            key={agency?.onboardingID}
            agencyData={{
              activeWorkers: agency?.metrics?.workers?.active,
              name: agency?.name,
              onboardingID: agency?.onboardingID,
              averageReliabilityScore: agency?.metrics?.workers?.averageReliabilityScore,
              availableRevenue: agency?.metrics?.revenue?.earnedTrailing12Month,
              scheduledRevenue: agency?.metrics?.revenue?.scheduled,
              openings: agency?.metrics?.futurePlacementsData?.totalOpenings,
              placements: agency?.metrics?.futurePlacementsData?.totalActivePlacements,
              fillRate:
                (agency?.metrics?.futurePlacementsData?.totalActivePlacements || 0) /
                (agency?.metrics?.futurePlacementsData?.totalOpenings || 0),
              location: agency?.address?.city
                ? `${agency?.address?.city}, ${agency?.address?.state}`
                : '--',
            }}
          />
        ))}
        filters={
          <AgencyFilters
            isLoading={isLoading}
            handleOnNameSearchChange={handleOnNameSearchChange}
            initialValues={{ value: searchParams.get(AGENCY_SEARCH_PARAM_KEYS.AGENCY) }}
          />
        }
        title={LanguageConverter('dashboard.agencies.title')}
      />

      <div ref={sentinelRef} />
    </Box>
  );
};

export default Agencies;
